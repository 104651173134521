/*
Theme Name: Adrian Theme UX 
Theme URI: cristianoux.com.br
Author: Adrian Cris Acosta
Author URI: cristianoux.com.br
Description:
Version: 2024.8.29
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: black, green, white, light, dark, two-columns, three-columns, left-sidebar, right-sidebar, fixed-layout, responsive-layout, custom-background, custom-header, custom-menu, editor-style, featured-images, flexible-header, full-width-template, microformats, post-formats, rtl-language-support, sticky-post, theme-options, translation-ready, accessibility-ready
Text Domain: adrian
This theme, like WordPress, is licensed under the GPL.
*/
body {
    margin: 0 !important;
    padding: 0 !important;
    background: #1d1d1d !important;
}
body.block-editor-page .edit-post-visual-editor__post-title-wrapper,
body.block-editor-page .editor-styles-wrapper {
    max-width: 1140px; /* Corresponde ao max-width do Bootstrap para desktops */
    margin-left: auto;
    margin-right: auto;
}
svg {
    fill: currentColor !important;
}
nav#main-nav {
    padding: 24px 0 0 0;
    border: 0 none;
    margin: 0 !important;    
    .material-symbols-outlined, .bi, i[class^='fa-'],  i[class*='fa-'] {
        font-size: 18px !important;
    }
    a.navbar-brand {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 60px;
        padding: 6px;
        margin: 0;
        border: 0 none;
        border-right: 1px solid rgba($color: #fafcff, $alpha: 0.06);
        img { 
            width: 100%;
            height: 100%;
        }
    }
    a.nav-link {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 !important;
        height: 60px;
        min-width: 60px;
        justify-content: center;
        text-align: center;
        font-size: 100%;
        color: #ffffff;
        &:hover, &:focus {
            background: rgba($color: #ffffff, $alpha: 0.06);
        }
        &:active, &.active {
            color: #ffffff;
        }
    }
    a.dropdown-item {
        display: flex;
        align-items: center;
        justify-content: left;
        text-align: left;
        padding: 0 12px;
        height: 60px;
        gap: 8px;
        &:active, &.active {
            color: #ffffff;
        }         
    }
    ul.dropdown-menu {
        padding: 0;
    }
}
header.hero-header {
    height: 100vh;
    .vegas-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        padding: 0 16px; /* Opcional: para garantir que o texto n�o toque as bordas em telas menores */
    }
    h1 {
        span, strong {
            display: block;
        }
    }
}